// Variable overrides
$body-bg: #ffffff;

$theme-colors: (
  "primary": gray
);

@import "~bootstrap/scss/bootstrap";
@import '~react-redux-toastr/lib/css/react-redux-toastr.min.css';

* {
  font-family: 'Inter', sans-serif;
}

h2 {
  color: blue;
}

.title {
  margin-right: 1em;
}

.backgroundPic {
  background: url('./../../assets/img/icons/a.png') no-repeat center;
  background-size: contain;

  &:hover {
    background-image: url('./../../assets/img/icons/b.png');
  }
}

.right {
  float: right;
  width: 32px;
  height: 32px;
}

/*** Notifications ***/
/*** Example rules ***/
.redux-toastr .bottom-right {
  max-width: 460px;
  width: fit-content;
  margin-right: 20px;
  margin-bottom: 24px;
  padding: 0;
}

.redux-toastr .toastr {
  min-height: 56px;
  border-radius: 8px;
  min-height: 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  width: inherit;
  margin: 0;
  margin-top: 8px;

  &.rrt-error,
  &.rrt-success,
  &.rrt-warning,
  &.rrt-info {
    background-color: #F7F9FC;
    border-radius: 8px;
  }

  .rrt-left-container {
    width: 56px;
    min-height: 56px;
    display: flex;
    align-items: center;

    .rrt-holder {
      width: 56px;
      min-height: 56px;
      display: flex;
      align-items: center;
      padding-left: 24px;
      padding-right: 12px;
      position: initial;
      margin-top: 0;
    }
  }

  .rrt-middle-container {
    min-height: 40px;
    display: flex;
    align-items: center;
    width: max-content;
    max-width: 650px;
    margin-left: 56px;
    padding: 16px 20px 16px 0px;

    .rrt-title {
      color: #5A6376;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.28px;
      margin-bottom: 0;
      max-width: 384px;
    }
  }

  .rrt-right-container {
    display: none;
    width: 0%;
  }
}

.rteTextbox {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.mt-12 {
  margin-top: 12px;
}

hr {
  border-top: 1px solid #E2E8F0;
}

p,
.form-group {
  margin-bottom: 0;
}

.gap-4 {
  gap: 24px
}

form>.form-group,
form>div.form-group>.form-group {
  margin-bottom: 16px !important;
}

form>.form-group:last-child,
form>div.form-group>.form-group:last-child {
  margin-bottom: 0 !important;
}
